import { ReduxAction } from 'types';
import { SET_STORE_ORDERS, UPDATE_STORE_ORDERS, REST_STORE_ORDERS } from 'actions/types';

const initialState = {
  manageOrders: {
    pendingall: { isFetching: false, data: [] },
    pendingcod: { isFetching: false, data: [] },
    pendingprepaid: { isFetching: false, data: [] },
    dropshipordered: { isFetching: false, data: [] },
    pendingdelivery: { isFetching: false, data: [] },
    outfordelivery: { isFetching: false, data: [] },
    faileddelivery: { isFetching: false, data: [] },
    delivered: { isFetching: false, data: [] },
    canceled: { isFetching: false, data: [] },
    rto: { isFetching: false, data: [] },
    returned: { isFetching: false, data: [] },
    all: { isFetching: false, data: [] },
    failedtosync: { isFetching: false, data: [] },
  }
};

export default (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case SET_STORE_ORDERS: {
      return {
        ...state,
        manageOrders: {
          ...state.manageOrders,
          [action.payload.orderType]: {
            // @ts-ignore
            ...state.manageOrders[action.payload.orderType],
            ...action.payload.data
          },
        }
      };
    }
    case UPDATE_STORE_ORDERS: {
      return {
        ...state,
        manageOrders: {
          ...state.manageOrders,
          [action.payload.orderType]: {
            // @ts-ignore
            ...state.manageOrders[action.payload.orderType],
            ...action.payload.data
          },
        }
      }
    }
    case REST_STORE_ORDERS: {
      return {
        ...state,
        manageOrders: {
          ...initialState.manageOrders,
        }
      }
    }
    default:
      return state;
  }
};
