import { DrawerState } from "../types";
import { UPDATE_DRAWER } from "actions/types";

interface Action {
  payload: DrawerState;
  type: string;
}

const initialState: DrawerState = {
  bottomNav: { isVisible: true, data: {} }
};

export default function (state = initialState, action: Action) {
  switch (action.type) {
    case UPDATE_DRAWER: {
      const key = Object.keys(action.payload)[0];

      if (!key) return state;
      return {
        ...state,
        [key]: { 
          ...state[key],
          isVisible: action.payload[key]?.isVisible,
          data: {
            ...state[key]?.data,
            ...action.payload[key]?.data,
          },
        },
      };
    }
    default:
      return state;
  }
}
