import { ReduxAction } from 'types';
import { SET_NDR, UPDATE_NDR, REST_NDR } from 'actions/types';

const initialState = {
  ndrOrders: {
    faileddelivery: { isFetching: false, data: [], ordersCount: 0 },
    outfordelivery: { isFetching: false, data: [], ordersCount: 0 },
    delivered: { isFetching: false, data: [], ordersCount: 0 },
    rto: { isFetching: false, data: [], ordersCount: 0 },
  },
};

export default (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case SET_NDR: {
      return {
        ...state,
        ndrOrders: {
          ...state.ndrOrders,
          [action.payload.orderType]: {
            // @ts-ignore
            ...state.ndrOrders[action.payload.orderType],
            ...action.payload.data
          },
        }
      };
    }
    case UPDATE_NDR: {
      return {
        ...state,
        ndrOrders: {
          ...state.ndrOrders,
          [action.payload.orderType]: {
            // @ts-ignore
            ...state.ndrOrders[action.payload.orderType],
            ...action.payload.data
          },
        }
      }
    }
    case REST_NDR: {
      return {
        ...state,
        ndrOrders: {
          ...initialState.ndrOrders,
        }
      }
    }
    default:
      return state;
  }
};
