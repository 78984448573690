import { ReduxAction } from 'types';
import { SET_RTO_PROBABLITY_FAQS, SET_RTO_FILTER_REASONS, SET_FAILED_TO_SYNC_REASONS, GET_RETURN_ORDER_REASONS, GET_CANCEL_RETURN_REASONS } from 'actions/types';

const initialState = {
  rtoFAQs: {},
  rtoFilterOptions: [],
  failedToSyncReasons: [],
  returnOrderReasons: [],
  cancelReturnReasons: [],
};

export default (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case SET_RTO_PROBABLITY_FAQS: {
      return {
        ...state,
        rtoFAQs: action.payload,
      };
    }
    case SET_RTO_FILTER_REASONS: {
      return {
        ...state,
        rtoFilterOptions: action.payload,
      };
    }
    case SET_FAILED_TO_SYNC_REASONS: {
      return {
        ...state,
        failedToSyncReasons: action.payload
      }
    }
    case GET_RETURN_ORDER_REASONS: {
      return {
        ...state,
        returnOrderReasons: action.payload
      }
    }
    case GET_CANCEL_RETURN_REASONS: {
      return {
        ...state,
        cancelReturnReasons: action.payload
      }
    }
    default:
      return state;
  }
};
