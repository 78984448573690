export enum EventName {
  ImpressionEvent = 'ImpressionEvents',
  ClickEvent = 'ClickEvents',
}

export enum EventType {
  Impression = 'impression',
  Click = 'click',
  Error = 'error',
  Feedback = 'feedback',
}

export enum EventProperty {
  StoreId = 'strid',
  SessionId = 'sid',
  UserId = 'uid',
  Platform = 'plt',
  OS = 'os',
  OSVersion = 'os_v',
  DeviceInfo = 'device_info',
  AppVersion = 'app_v',
  PageName = 'pg_nm',
  SourcePageName = 'src_pg_nm',
  IP = 'ip',
  EventType = 'ety',
  Timestamp = 'ts',
  EntityID = 'entity_id',
  EntityType = 'entity_typ',
  Action = 'act',
  Extras = 'ext',
  PageUrl = 'pg_url',
  SourcePageUrl = 'src_pg_url',
  AppSessionId = 'app_sid',
}

export enum Platform {
  NativeApp = 'native-app',
  Desktop = 'desktop'
}
