import { PortraitProductVideoPlayerState, Product } from "../types";
import { UPDATE_PORTRAIT_VIDEO_PLAYER, SET_PORTRAIT_VIDEO_PLAYER, UPDATE_PORTRAIT_VIDEO_PLAYER_PRODUCT } from "actions/types";

interface Action {
  payload: PortraitProductVideoPlayerState;
  type: string;
}

const initialState: PortraitProductVideoPlayerState = {
  videoPlayerType: '',
  videoFeedType: '',
  isVisible: false,
  products: [],
  headerIcon: '',
  headerName: '',
  redirectUrl: '',
};

export default function (state = initialState, action: Action) {
  switch (action.type) {
    case SET_PORTRAIT_VIDEO_PLAYER: 
      return {
        isVisible: action.payload.isVisible,
        products: [...action.payload.products],
        headerIcon: action.payload.headerIcon,
        headerName: action.payload.headerName,
        redirectUrl: action.payload.redirectUrl,
      };
    case UPDATE_PORTRAIT_VIDEO_PLAYER:{
      const updatedProducts = [...state.products, ...action.payload.products];

      // Remove duplicates
      const uniqueProducts = updatedProducts.reduce((acc: Product[], product) => {
        const isDuplicate = acc.some((item: Product) => item.productId === product.productId);
        if (!isDuplicate) acc.push(product);

        return acc;
      }, []);

      return {
        ...state,
        ...action.payload,
        products: uniqueProducts,
      };
    }
    case UPDATE_PORTRAIT_VIDEO_PLAYER_PRODUCT: {
      const productIdx = state?.products?.findIndex((product: Product) => product.productId === action.payload?.productId);

      if (productIdx === -1) return state;

      const tempProductList = [...state.products];
      // @ts-ignore
      tempProductList[productIdx] = { ...tempProductList[productIdx], ...action.payload?.data };

      return {
        ...state,
        products: tempProductList
      }
    };
    default:
      return state;
  }
}
