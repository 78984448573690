import { apiCall } from "utils/api";
import { sellerStoreId, userId } from '../utils/auth';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/users`;

export const getPersonalDetails = async () => {
    const response = await apiCall({
      method: 'get',
      url: `${HOST}/${userId()}/store/${sellerStoreId()}/profile`,
    });
  
    return response.data;
};

export const updatePersonalDetails = async (data: any) => {
    const response = await apiCall({
      method: 'put',
      url: `${HOST}/${userId()}/store/${sellerStoreId()}/profile`,
      data,
    });
  
    return response.data;
};
