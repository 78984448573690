import {combineReducers} from 'redux'
import userReducer from './usersReducer'
import productsReducer from './productsReducer'
import ordersReducer from './ordersReducer'
import storeOrdersReducer from './storeOrdersReducer'
import ndrReducer from './ndrReducer'
import localStorageReducer from './localStorageReducer'
import dashboardSettingsReducer from './dashboardSettingsReducer'
import cookieStorageReducer from './cookieStorageReducer'
import headerNotificationReducer from './headerNotificationReducer'
import drawerReducer from './drawerReducer'
import portraitProductVideoPlayerReducer from './portraitProductVideoPlayerReducer'

export default combineReducers({
  user: userReducer,
  product: productsReducer,
  orders: ordersReducer,
  storeOrders: storeOrdersReducer,
  manageNdr: ndrReducer,
  localStorageValues: localStorageReducer,
  cookieStorageValues: cookieStorageReducer,
  dashboardSettings: dashboardSettingsReducer,
  headerNotification: headerNotificationReducer,
  drawer: drawerReducer,
  portraitProductVideoPlayer: portraitProductVideoPlayerReducer,
})