import { ReduxAction } from '../types';
import {
  SET_IN_COOKIE,
  DELETE_FROM_COOKIE,
} from 'actions/types';

const initialState = {};

export default function (state = initialState, action: ReduxAction) {
  switch (action.type) {
    case SET_IN_COOKIE:{
      return ({
        ...state,
        [action.payload.key]: {
          // @ts-ignore
          ...state[action.payload.key],
          ...action.payload
        },
      })
    }
    case DELETE_FROM_COOKIE: {
      if (action.payload.isError) {
        return ({
          ...state,
          [action.payload.key]: {
            // @ts-ignore
            ...state[action.payload.key],
            ...action.payload
          },
        })
      }

      const tempState = { ...state };

      if (Object.keys(tempState).includes(action.payload.key)) {
        // @ts-ignore
        delete tempState[action.payload.key];
      }

      return ({ ...tempState });
    }
    default:
      return state
  }
}