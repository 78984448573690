import {SET_SHOPIFY_STORE_DATA, SET_SHOPIFY_STORE_ERROR} from "types";
import { SET_HAS_UNREAD_CLOUT_POST, UPDATE_USER_COUNTRY_PREFERENCE, SET_WHATSAPP_SUBSCRIPTION, SET_IMAGE_SEARCH_DATA, UPDATE_PDP_ACTIVE_NAV } from './types';
import { getShopifyStoreData as getShopifyStoreDataApi, getCloutUpdatePosts as getCloutUpdatePostsApi } from 'data/shopify';
import { getPersonalDetails } from "data/users";
import {isStaff, isUserLoggedIn} from "../utils/auth";
import {
  getWhatsappSubscriptionDetails as getWhatsappSubscriptionDetailsApi
} from '../data/clout';

const setShopifyStoreData = (data: {}) => ({
  type: SET_SHOPIFY_STORE_DATA,
  payload: data,
});

const setShopifyStoreError = (error: {}) => ({
  type: SET_SHOPIFY_STORE_ERROR,
  payload: error,
});

export const updateUserCountryPreferenceAction = (data: {}) => ({
  type: UPDATE_USER_COUNTRY_PREFERENCE,
  payload: data,
});

export const setWhatsappSubscriptionAction = (data: {}) => ({
  type: SET_WHATSAPP_SUBSCRIPTION,
  payload: data,
});

export const updatePdpActiveNav = (data: {}) => ({
  type: UPDATE_PDP_ACTIVE_NAV,
  payload: data,
});

export const updatePdpActiveNavState = (activeNav: string) => {
  return updatePdpActiveNav(activeNav);
}

export const getShopifyStoreData = () => async (dispatch: any) => {
  try {
    const shopifyStoreData = await getShopifyStoreDataApi();
    let cloutProfileDetails={};
    if(!isStaff() && isUserLoggedIn()){
      cloutProfileDetails = await getPersonalDetails();
    }
    dispatch(setShopifyStoreData({ ...shopifyStoreData, ...cloutProfileDetails }));
  } catch (error) {
    dispatch(setShopifyStoreError(error));
  }
}

export const getCloutUnreadPostStatus = () => async (dispatch: any) => {
  const response = await getCloutUpdatePostsApi();
  dispatch({ type: SET_HAS_UNREAD_CLOUT_POST, payload: !response.visitedAllPosts });
};

export const getWhatsappSubscriptionData = () => async (dispatch: any) => {
  try {
    const whatsappSubscriptionDetails = await getWhatsappSubscriptionDetailsApi();
    dispatch(setWhatsappSubscriptionAction(whatsappSubscriptionDetails));
  } catch (error) {
    dispatch(setWhatsappSubscriptionAction({}));
  }
}

export const setImageSearchDataAction = (data: {}) => ({
  type: SET_IMAGE_SEARCH_DATA,
  payload: data,
});
