import { ReduxAction } from '../types';
import {
  REMOVE_FROM_LOCAL_STORAGE,
  SET_IN_LOCAL_STORAGE,
  CLEAR_LOCAL_STORAGE,
} from 'actions/types';

const initialState = {};

export default function (state = initialState, action: ReduxAction) {
  switch (action.type) {
    case SET_IN_LOCAL_STORAGE:{
      if (action.payload.isError) {
        return ({
          ...state,
          isError: action.payload.isError,
        })
      }

      return ({
        ...state,
        [action.payload.key]: action.payload.value,
        isError: action.payload.isError,
      })
    }
    case REMOVE_FROM_LOCAL_STORAGE: {
      if (action.payload.isError) {
        return ({
          ...state,
          isError: action.payload.isError,
        })
      }

      const tempState = { ...state };

      if (Object.keys(tempState).includes(action.payload.key)) {
        // @ts-ignore
        delete tempState[action.payload.key];
      }

      return ({
        ...tempState,
        isError: action.payload.isError
      });
    }
    case CLEAR_LOCAL_STORAGE: {
      if (action.payload.isError) {
        return ({
          ...state,
          isError: action.payload.isError,
        })
      }
  
      return ({
        isError: action.payload.isError
      });
    }
    default:
      return state
  }
}