import { NotificationProps, ReduxAction } from "../types";
import {
  UPDATE_NOTIFICATION_DETAILS,
  SET_NOTIFICATION_COUNT,
  UPDATE_NOTIFICATION_DATA,
  SET_NOTIFICATION_DATA,
  SET_NOTIFICATION_IS_UNREAD_ONLY,
  UPDATE_NOTIFICATION_INFO,
} from "actions/types";

const initialState = {
  notificationCount: 0,
  notificationData: { limit: 10, offset: 0, notifications: [] },
  isUnreadOnly: false,
};

export default function (state = initialState, action: ReduxAction) {
  switch (action.type) {
    case UPDATE_NOTIFICATION_DETAILS:
      return {
        ...state,
        ...action.payload,
        notificationData: { ...state.notificationData, ...action.payload.notificationData },
      };
    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.payload,
      };
    case UPDATE_NOTIFICATION_DATA:
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          ...action.payload,
          notifications: [
            ...state.notificationData.notifications,
            ...action.payload.notifications,
          ],
        },
      };
    case SET_NOTIFICATION_DATA:
      return {
        ...state,
        notificationData: { ...action.payload },
      };
    case UPDATE_NOTIFICATION_INFO: {
      // Get index of updated notification
      const notificationIndex = state.notificationData.notifications.findIndex(
        (notification: NotificationProps) =>
          notification.notificationId ===
          action.payload.notificationId
      );

      // If notification exist
      if (notificationIndex >= 0) {
        const tempNotificationsList: NotificationProps[] = [
          ...state.notificationData.notifications,
        ];

        // Update the updated notification property to the respective index
        tempNotificationsList[notificationIndex] = {
          ...tempNotificationsList[notificationIndex],
          ...action.payload,
        };

        return {
          ...state,
          notificationData: {
            ...state.notificationData,
            notifications: tempNotificationsList,
          },
        };
      }

      return { ...state };
    }
    case SET_NOTIFICATION_IS_UNREAD_ONLY:
      return {
        ...state,
        isUnreadOnly: action.payload,
      };
    default:
      return state;
  }
}
