import {v4} from "uuid";
import rnaAnalytics from 'services/analytics/rnaAnalytics';
import { COOKIES, getCookie, SESSION_KEY } from "utils/cookies";
import { sellerStoreId, sessionId, userId } from "utils/auth";
import { useEffect } from "react";

const AppAnalytics = () => {
  const initAnalytics = () => {
    rnaAnalytics.init({
      storeId: sellerStoreId(),
      sessionId: sessionId(),
      userId: userId(),
      nativeAppVersion: getCookie(null, COOKIES.nativeAppVersion),
    });
  }

  useEffect(() => {
    if (!sessionStorage.getItem(SESSION_KEY.appSessionId)) sessionStorage.setItem(SESSION_KEY.appSessionId, v4());
    window.sessionStorage.setItem(SESSION_KEY.prevUrl, window.location.href);
    
    initAnalytics();
  }, [])

  return <></>;
}

export default AppAnalytics