export enum PAGES_ENUM {
  HOME = "/",
  MANAGE_ORDER = "/order/manage/",
  MANAGE_NDR = "/manage-ndr/",
  COLLECTION = "/collection",
  CATEGORY = "/category",
  ANALYTICS = "/dashboard/analytics",
  PUSHED_TO_SHOPIFY = "/manage-products/pushedToShopify",
  INVENTORY_REQUESTS = "/manage-products/inventoryRequests",
  SOURCE_PRODUCT = "/source-product",
  REPORTS = "/reports",
  PAYMENTS = "/payments",
  WISHLIST = "/wishlist",
  WHATSAPP = "/dashboard/whatsapp",
  CLOUT_UPDATES = "/clout-updates",
  PROFILE = "/profile",
  STORE_MANAGER = "/store-manager",
  STAFF_ACCESS_MANAGEMENT = "/staff-access-management",
}
