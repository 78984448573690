import { getPageNameFromUrl } from "utils/misc";
import { EventName, EventProperty, EventType, Platform } from "./constants";
import { EventData, InitType, EventTypeData } from "./types";
import { getPlatformInfo } from "./utils";

const HOST = `${process.env.NEXT_PUBLIC_ANALYTICS_URL}/eventserver/api/publish/analytics-events`;

class RnaAnalytics {
  private analyticsData = {
    appInfo: {
      storeId: '',
      sessionId: '',
      userId: '',
      platform: '',
      os: '',
      osVersion: '',
      deviceInfo: '',
      appVersion: '',
      ip: '',
    },
    eventList: [],
    currentUrl: '',
    previousUrl: ''
  }

  constructor() {
    this.setClintIP();
    this.setupUrlTracking();
  }

  private setupUrlTracking() {
    if (typeof window === 'undefined') return;

    // Store initial URL
    this.analyticsData.currentUrl = window.location.href;

    // Listen for popstate (browser back/forward)
    window.addEventListener('popstate', () => {
      this.handleUrlChange();
    });

    // Create observer for URL changes via history.pushState/replaceState
    const observer = new MutationObserver(() => {
      if (this.analyticsData.currentUrl !== window.location.href) {
        this.handleUrlChange();
      }
    });

    // Observe document title changes as a proxy for navigation
    const titleElement = document.querySelector('title');
    if (titleElement) {
      observer.observe(titleElement, {
        subtree: true,
        characterData: true,
        childList: true
      });
    }

    // Intercept history methods
    const originalPushState = history.pushState;
    const originalReplaceState = history.replaceState;

    history.pushState = (...args) => {
      originalPushState.apply(history, args);
      this.handleUrlChange();
    };

    history.replaceState = (...args) => {
      originalReplaceState.apply(history, args);
      this.handleUrlChange();
    };
  }

  private handleUrlChange() {
    this.analyticsData.previousUrl = this.analyticsData.currentUrl;
    this.analyticsData.currentUrl = window.location.href;
    
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('prevPageUrl', this.analyticsData.previousUrl);
    }
  }

  private getCurrentPageUrl(): string {
    if (typeof window === 'undefined') return '';
    return window.location.href;
  }

  private getPreviousPageUrl(): string {
    if (typeof window === 'undefined') return '';
    return window.sessionStorage.getItem('prevPageUrl') || '';
  }

  /**
   * Get event data with URL information at the time of event
   */
  private getEventData({ eventName, eventType, action, eventData }: EventData) {
    // Capture URLs at the exact moment the event is created
    const currentUrl = this.getCurrentPageUrl();
    const previousUrl = this.getPreviousPageUrl();

    const eventProperty = {
      ...eventData,
      ...(eventData?.ext && { [EventProperty.Extras]: JSON.stringify(eventData.ext) }),
      ...(eventData?.entity_typ && {[EventProperty.EntityType]: eventData.entity_typ}),
      ...((eventData?.entity_id || eventData?.entity_id === 0) && {[EventProperty.EntityID]: eventData.entity_id}),
      [EventProperty.Timestamp]: Date.now(),
      [EventProperty.EventType]: eventType,
      [EventProperty.Action]: action,
    };

    // Add URL information to each event
    const pageUrl = {
      [EventProperty.PageUrl]: currentUrl,
      [EventProperty.SourcePageUrl]: previousUrl,
      [EventProperty.PageName]: getPageNameFromUrl(currentUrl),
      [EventProperty.SourcePageName]: getPageNameFromUrl(previousUrl),
    }

    return {
      data: { eventName, eventProperty },
      pageUrl,
    };
  }

  private async setClintIP() {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      this.analyticsData.appInfo.ip = data.ip;
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Common event properties
   * @returns baseEvents object
   */
  private getBaseEvents() {
    return {
      [EventProperty.StoreId]: this.analyticsData.appInfo.storeId,
      [EventProperty.SessionId]: this.analyticsData.appInfo.sessionId,
      [EventProperty.UserId]: this.analyticsData.appInfo.userId,
      [EventProperty.Platform]: this.analyticsData.appInfo.platform,
      [EventProperty.OS]: this.analyticsData.appInfo.os,
      [EventProperty.OSVersion]: this.analyticsData.appInfo.osVersion,
      [EventProperty.DeviceInfo]: this.analyticsData.appInfo.deviceInfo,
      [EventProperty.AppVersion]: this.analyticsData.appInfo.appVersion,
      [EventProperty.IP]: this.analyticsData.appInfo.ip,
      [EventProperty.AppSessionId]: typeof window !== 'undefined' ? (window.sessionStorage.getItem('appSessionId') || '') : '',
    }
  }

  /**
   * Initialize RNA analytics
   */
  init({ nativeAppVersion = '', ...appInfo }: InitType) {
    const platformInfo = getPlatformInfo();
    const devicePlatform = nativeAppVersion ? Platform.NativeApp : platformInfo?.device?.type || Platform.Desktop;
    const appVersion = `${nativeAppVersion ? nativeAppVersion+'/' : ''}${platformInfo?.browser?.name || ''}/${platformInfo?.browser?.version || ''}`;

    const data = {
      ...this.analyticsData.appInfo,
      storeId: appInfo?.storeId || '',
      sessionId: appInfo?.sessionId || '',
      userId: appInfo?.userId || '',
      platform: devicePlatform,
      os: platformInfo?.os?.name || '',
      osVersion: platformInfo?.os?.version || '',
      deviceInfo: `${platformInfo?.device?.vendor || ''}_${platformInfo?.device?.model || ''}`,
      appVersion: appVersion,
    };

    this.analyticsData.appInfo = data;
  }

  /**
   * Add custom events
   */
  event({ eventName, eventType, action, eventData }: EventData) {
    const evt = this.getEventData({ eventName, eventType, action, eventData });

    // @ts-ignore
    this.analyticsData.eventList.push(evt);

    return this;
  }

  /**
   * Add Impression Event
   */
  impressionEvent({ action, eventData }: EventTypeData) {
    return this.event({ eventName: EventName.ImpressionEvent, eventType: EventType.Impression, action, eventData });
  }

  /**
   * Add Click Event
   */
  clickEvent({ action, eventData }: EventTypeData) {
    return this.event({ eventName: EventName.ClickEvent, eventType: EventType.Click, action, eventData });
  }

  /**
   * Add Error Event
   */
  errorEvent({ action, eventData }: EventTypeData) {
    return this.event({ eventName: EventName.ImpressionEvent, eventType: EventType.Error, action, eventData });
  }

  /**
   * Add Feedback Event
   */
  feedbackEvent({ action, eventData }: EventTypeData) {
    return this.event({ eventName: EventName.ImpressionEvent, eventType: EventType.Feedback, action, eventData });
  }

  /**
   * Send event
   */
  async send() {
    try {
      const payload: { [key: string]: any } = {};
      const baseEvents = this.getBaseEvents();
      let pageUrl: { [key: string]: any } = {};

      this.analyticsData.eventList.map((eventInfo: any) => {
        pageUrl = { ...eventInfo.pageUrl };
        const event = eventInfo.data;
        if (payload[event.eventName]) payload[event.eventName].push(event.eventProperty);
        else payload[event.eventName] = [{...event.eventProperty}];
      });

      // Reset event list
      this.analyticsData.eventList = [];

      await fetch(HOST, {
        headers: {
          ...baseEvents,
          ...pageUrl,
          'content-type': 'application/json'
        },
        body: JSON.stringify(payload),
        method: 'POST',
      })
    } catch (err) {
      this.analyticsData.eventList = [];
      console.error(err);
    }
  }
}

const rnaAnalytics = new RnaAnalytics();
Object.freeze(rnaAnalytics);

export default rnaAnalytics;
