import UAParser from "ua-parser-js";

export const getPlatformInfo = () => {
  const uaParser = new UAParser();
  return uaParser.getResult();
}

export const deepFreeze = (obj: any) => {
  if (obj && typeof obj === "object" && !Object.isFrozen(obj)) {
     Object.freeze(obj);
     Object.getOwnPropertyNames(obj).forEach(prop => deepFreeze(obj[prop]));
   }
 return obj;
};
