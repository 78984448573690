import { ReduxAction } from '../types';
import { UPDATE_DASHBOARD_SETTINGS } from 'actions/types';

const initialState = {
  ndrCalculationBasedOn: '',
}

export default function (state = initialState, action: ReduxAction) {
  switch (action.type) {
    case UPDATE_DASHBOARD_SETTINGS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}