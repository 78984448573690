import usCountryIcon from "assets/US.svg";
import internationalCountryIcon from "assets/International.svg";
import indiaCountryIcon from "assets/India.svg";

export enum CountryCode {
  ALL = 'ALL',
  IN = 'IN',
  US = 'US',
  INT = 'INTL',
}

export const COUNTRY_CODE = {
  all: CountryCode.ALL,
  india: CountryCode.IN,
  us: CountryCode.US,
  international: CountryCode.INT,
}

export const COUNTRY = {
  india: { label: "India", icon: indiaCountryIcon, code: COUNTRY_CODE.india },
  us: { label: "US", icon: usCountryIcon, code: COUNTRY_CODE.us }, 
  international: { label: "International", icon: internationalCountryIcon, code: COUNTRY_CODE.international }, 
}

export const COUNTRY_KEY = {
  [COUNTRY_CODE.india]: { label: "India", icon: indiaCountryIcon },
  [COUNTRY_CODE.us]: { label: "US", icon: usCountryIcon },
  [COUNTRY_CODE.international]: { label: "International", icon: internationalCountryIcon },
}

export enum PLATFORM_TYPE {
  Android = 'android',
  IOS = 'iOS',
  desktop = 'desktop',
  mobile = 'mobile',
  tablet = 'tablet',
}
