import { Product, ReduxAction } from 'types';
import { 
  SET_PRODUCT_CATEGORIES,
  SET_PRODUCT_COLLECTIONS,
  SET_PRODUCT_FEED,
  UPDATE_PRODUCT_COLLECTION,
  UPDATE_PRODUCT_COLLECTION_PRODUCTS,
  UPDATE_PRODUCT_FEED,
  SET_WISHLIST,
  RESET_WISHLIST,
  UPDATE_WISHLIST,
  UPDATE_WISHLIST_PRODUCT
} from 'actions/types';

const initialState = {
  productCategories: [],
  productCollections: [],
  productFeed: {
    isFetching: false,
  },
  wishlist: {
    isFetching: false,
    products: [],
    totalProducts: 0,
    isError: false,
  }
};

export default (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case SET_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: action.payload,
      };
    case SET_PRODUCT_COLLECTIONS:
      return {
        ...state,
        productCollections: [
          ...state.productCollections,
          ...action.payload
        ],
      };
    case UPDATE_PRODUCT_COLLECTION:
      return {
        ...state,
        productCollections: action.payload,
      };
    case UPDATE_PRODUCT_COLLECTION_PRODUCTS: {
      const collectionIdx = state.productCollections.findIndex((collection: any) => collection.collectionId === action.payload.collectionId);

      if (collectionIdx === -1 ) return state;

      let tempProductCollections = [...state.productCollections];
      // @ts-ignore
      tempProductCollections[collectionIdx].products = [...tempProductCollections[collectionIdx].products, ...action.payload.products];
      
      return ({
        ...state,
        productCollections: tempProductCollections
      });
    }
    case SET_PRODUCT_FEED:
      return {
        ...state,
        productFeed: action.payload,
      };
    case UPDATE_PRODUCT_FEED:
      return {
        ...state,
        productFeed: {
          ...state.productFeed,
          ...action.payload,
        },
      };
    case SET_WISHLIST: {
      return {
        ...state,
        wishlist: {
          ...action.payload,
        },
      }
    };
    case UPDATE_WISHLIST: {
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          ...action.payload,
          products: [...state.wishlist.products, ...(action.payload?.products || [])]
        }
      }
    };
    case UPDATE_WISHLIST_PRODUCT: {
      const wishlistProductIdx = state.wishlist?.products.findIndex((product: Product) => product.productId === action.payload?.productId);

      if (wishlistProductIdx === -1 ) return state;

      const tempWishlistProducts = [...state.wishlist.products];
      // @ts-ignore
      tempWishlistProducts[wishlistProductIdx] = { ...tempWishlistProducts[wishlistProductIdx], ...action.payload?.data };

      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          ...action.payload,
          products: tempWishlistProducts
        }
      }
    };
    case RESET_WISHLIST: {
      return {
        ...state,
        wishlist: initialState.wishlist,
      }
    };
    default:
      return state;
  }
};
